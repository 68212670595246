import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <div className="page-home">
    <SEO title="Home" keywords={[`mark`, `beeson`, `engineering`]}/>
    {/*<div className="background" style={{ backgroundImage: `url(${data.file.headerOne.fluid.src})` }}/>*/}
    <div className="video-background" dangerouslySetInnerHTML={{
      __html: `
      <video autoPlay muted loop poster="home-bg-poster.jpg">
        <source src="home-bg-720.mp4" type="video/mp4"/>
      </video>
    `,
    }}/>
    <div className="row-top">
      <h1>Mark Beeson</h1>
      <h5>Creating web technology and energizing engineers since 1994.</h5>
    </div>
    <div className="row">
      <div className="col col-left">
        <p>
          From the real estate industry, to the fashion industry, to the video game industry, to the
          music industry, I have spent my career bringing companies into the 21st century. Digital
          transformations are my primary focus, having led multidisciplinary teams at startups and all the
          way up to billion-dollar corporations.
        </p>
        <p>
          Every day gets treated as a learning opportunity and an opportunity to trailblaze, both at a
          technical level and at a personal level in support of the teams that I lead. Using the
          experience gained from working on the web since its inception, I spend my energy helping
          develop engineering talent and finding the most appropriate technical solutions.
        </p>
        <p>
          <a href="Mark_Beeson_resume.pdf" className="action-link">View my resum&eacute;</a>
        </p>
      </div>
      <div className="space">&nbsp;</div>
      <div className="col col-right">
        <h5>
          Check out my latest article from an ongoing series about the future of web development &#x2192;
        </h5>
        <p>
          <Link to={`/articles${data.allMarkdownRemark.edges[0].node.fields.slug}`} className="headline">
            {`"${data.allMarkdownRemark.edges[0].node.frontmatter.title}"`}
          </Link>
        </p>
        <p>
          <Link to={"/articles/"} className="article-list action-link">List all articles</Link>
        </p>
      </div>
    </div>
    <div className="row-bottom">
      <div className="contact">Contact &#x2192;</div>
      <a href="https://twitter.com/markrbeeson" className="action-link">Twitter</a>
      <a href="https://linkedin.com/in/mark242/" className="action-link">LinkedIn</a>
      <a href="https://github.com/m242" className="action-link">GitHub</a>
      <a href="https://gitlab.com/markbeeson" className="action-link">GitLab</a>
      <a href="mailto:mark@beeson.email" className="action-link">Email</a>
    </div>
  </div>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [ frontmatter___date ]}, limit: 1) {
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date(formatString: "ddd MMM d, YYYY")
          }
          html
        }
      }
    }
  }
`

export default IndexPage
